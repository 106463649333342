<template>
    <div class="flex flex-col items-center gap-8 text-neutral-950">
        <h2
            v-if="showHeader"
            class="text-center font-600 text-[22px] leading-[35.55px] md:leading-[62px] lg:text-[56px]"
        >
            People who love travel love {{ config.public.appName }}
        </h2>
        <div class="grid auto-rows-min grid-cols-1 gap-6 lg:grid-cols-2">
            <HomePageTestimonialCard
                review-text="Ascend made my dream vacation a reality! I scored an unbeatable deal on a luxurious suite in Paris with a stunning view of the Eiffel Tower. Their price-match guarantee gave me peace of mind, knowing I got the best possible rate."
                first-name="Scott S."
                image-url="/images/homepage/testimonials/scott.jpg"
                trip-location="Paris"
                class="row-auto"
            />
            <HomePageTestimonialCard
                review-text="Thanks to Ascend, I discovered hidden gems in Tokyo that I never would have found on my own. The curated list of boutique hotels and insider tips made my trip truly unforgettable. I'm already planning my next adventure with Ascend!"
                first-name="Priya K."
                image-url="/images/homepage/testimonials/priya.jpeg"
                trip-location="Tokyo"
                class="row-auto"
            />
            <HomePageTestimonialCard
                review-text="Ascend's last-minute deals saved my business trip! I found a top-rated hotel near my conference venue at half the usual price, just hours before my flight.  It was intuitive and made booking on-the-go a breeze."
                first-name="David L."
                image-url="/images/homepage/testimonials/jake.jpg"
                trip-location="Seattle"
                class="lg:col-span-2 lg:row-auto"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()

withDefaults(
    defineProps<{
        showHeader?: boolean
    }>(),
    {
        showHeader: true,
    }
)
</script>
